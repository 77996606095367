import type { ReactNode, PropsWithChildren } from 'react';
import { useRef } from 'react';

import { withButtonAlignment } from '@skyscanner/backpack-web/bpk-component-icon';
import InformationCircleIconSm from '@skyscanner/backpack-web/bpk-component-icon/sm/information-circle';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';
import BpkTooltip from '@skyscanner/backpack-web/bpk-component-tooltip';
import { textColors } from '@skyscanner/bpk-foundations-web/tokens/base.es6';

import { InlineText, ParagraphSm } from '../HotelsCommon/Text';

import type {
  SortingLinksProps,
  SortingLinksSupportedIcon,
  ToolTip,
} from 'common-types/types/SortingLinkts';

import STYLES from './SortingLinks.module.scss';

const ICON_MAP: Record<SortingLinksSupportedIcon, ReactNode> = {
  info: InformationCircleIconSm,
};

const Tooltip = ({
  children = null,
  descriptions,
  title,
}: PropsWithChildren<{
  descriptions: ToolTip['descriptions'];
  title: ToolTip['title'];
}>) => {
  const iconRef = useRef(null);
  return (
    <BpkTooltip
      id="best-tooltip"
      ariaLabel={title}
      className={STYLES.tooltip}
      target={
        <span ref={iconRef} role="tooltip" data-testid="best-tooltip-wrapper">
          {children}
        </span>
      }
    >
      <BpkText
        tagName="h5"
        className={STYLES.tooltipHeader}
        textStyle={TEXT_STYLES.heading5}
      >
        {title}
      </BpkText>
      <div className={STYLES.tooltipParagraph}>
        {descriptions.map((description) => (
          <ParagraphSm key={description}>{description}</ParagraphSm>
        ))}
      </div>
    </BpkTooltip>
  );
};

const SortingLinks = ({ links, sortingTitle }: SortingLinksProps) => {
  const getSortingLinkIcon = (
    iconName?: keyof typeof ICON_MAP,
    toolTipData?: ToolTip,
  ) => {
    if (!iconName) {
      return null;
    }

    const Icon = withButtonAlignment(ICON_MAP[iconName]);

    if (toolTipData) {
      return (
        <Tooltip
          title={toolTipData.title}
          descriptions={toolTipData.descriptions}
        >
          <Icon fill={textColors.textLinkDay} />
        </Tooltip>
      );
    }
    return <Icon fill={textColors.textLinkDay} />;
  };

  return (
    <BpkMobileScrollContainer
      trailingIndicatorClassName={STYLES.Indicator}
      leadingIndicatorClassName={STYLES.Indicator}
    >
      <div className={STYLES.SortingLinks} data-testid="sorting-links-link">
        <BpkText className={STYLES.text}>{sortingTitle}</BpkText>

        {links.map(({ iconName, text, toolTip, url }) => (
          <div className={STYLES.link} key={text}>
            <BpkLink href={url} rel="nofollow">
              <InlineText className={STYLES.linkText}>{text}</InlineText>
              {getSortingLinkIcon(iconName, toolTip)}
            </BpkLink>
          </div>
        ))}
      </div>
    </BpkMobileScrollContainer>
  );
};

export default SortingLinks;
