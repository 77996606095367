import { TEXT_STYLES } from '@skyscanner/backpack-web/bpk-component-text';
import { withDefaultProps } from '@skyscanner/backpack-web/bpk-react-utils';

import BpkText from './BpkTextWrapper';

export const ParagraphXs = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.caption,
});
ParagraphXs.displayName = 'ParagraphXs';

export const ParagraphLabel3 = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.label3,
});
ParagraphXs.displayName = 'ParagraphLabel3';

export const ParagraphSm = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.footnote,
});
ParagraphSm.displayName = 'ParagraphSm';

export const ParagraphLabel2 = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.label2,
});
ParagraphSm.displayName = 'ParagraphLabel2';

export const Paragraph = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.bodyDefault,
});
Paragraph.displayName = 'Paragraph';

export const ParagraphLabel1 = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.label1,
});
Paragraph.displayName = 'ParagraphLabel1';

export const ParagraphLg = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.bodyLongform,
});
ParagraphLg.displayName = 'ParagraphLg';

export const ParagraphXl = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.subheading,
});
ParagraphXl.displayName = 'ParagraphXl';

export const ParagraphHeading3 = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.heading3,
});
ParagraphXl.displayName = 'ParagraphHeading3';

export const ParagraphXxl = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.heading2,
});
ParagraphXxl.displayName = 'ParagraphXxl';

export const ParagraphXxxl = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.heading1,
});
ParagraphXxxl.displayName = 'ParagraphXxxl';

export const ParagraphXxxxl = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.hero5,
});
ParagraphXxxxl.displayName = 'ParagraphXxxxl';

export const ParagraphXxxxxl = withDefaultProps(BpkText, {
  tagName: 'p',
  textStyle: TEXT_STYLES.hero4,
});
ParagraphXxxxxl.displayName = 'ParagraphXxxxxl';

export const InlineTextXs = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.caption,
});
InlineTextXs.displayName = 'InlineTextXs';

export const InlineTextLabel3 = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.label3,
});
InlineTextXs.displayName = 'InlineTextLabel3';

export const InlineTextSm = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.footnote,
});
InlineTextSm.displayName = 'InlineTextSm';

export const InlineTextLabel2 = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.label2,
});
InlineTextSm.displayName = 'InlineTextLabel2';

export const InlineText = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.bodyDefault,
});
InlineText.displayName = 'InlineText';

export const InlineTextLabel1 = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.label1,
});
InlineText.displayName = 'InlineTextLabel1';

export const InlineTextLg = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.bodyLongform,
});
InlineTextLg.displayName = 'InlineTextLg';

export const InlineTextHeading4 = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.heading4,
});
InlineTextLg.displayName = 'InlineTextHeading4';

export const InlineTextXl = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.subheading,
});
InlineTextXl.displayName = 'InlineTextXl';

export const InlineTextHeading3 = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.heading3,
});
InlineTextXl.displayName = 'InlineTextHeading3';

export const InlineTextXxl = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.heading2,
});
InlineTextXxl.displayName = 'InlineTextXxl';

export const InlineTextXxxl = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.heading1,
});
InlineTextXxxl.displayName = 'InlineTextXxxl';

export const InlineTextXxxxl = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.hero5,
});
InlineTextXxxxl.displayName = 'InlineTextXxxxl';

export const InlineTextXxxxxl = withDefaultProps(BpkText, {
  textStyle: TEXT_STYLES.hero4,
});
InlineTextXxxxxl.displayName = 'InlineTextXxxxxl';

export const Header1Lg = withDefaultProps(BpkText, {
  tagName: 'h1',
  textStyle: TEXT_STYLES.bodyLongform,
});
Header1Lg.displayName = 'Header1Lg';

export const Header1Xl = withDefaultProps(BpkText, {
  tagName: 'h1',
  textStyle: TEXT_STYLES.subheading,
});
Header1Xl.displayName = 'Header1Xl';

export const Header1Xxl = withDefaultProps(BpkText, {
  tagName: 'h1',
  textStyle: TEXT_STYLES.heading2,
});
Header1Xxl.displayName = 'Header1Xxl';

export const Header1Xxxl = withDefaultProps(BpkText, {
  tagName: 'h1',
  textStyle: TEXT_STYLES.heading1,
});
Header1Xxxl.displayName = 'Header1Xxxl';

export const Header1Xxxxl = withDefaultProps(BpkText, {
  tagName: 'h1',
  textStyle: TEXT_STYLES.hero5,
});
Header1Xxxxl.displayName = 'Header1Xxxxl';

export const Header1Xxxxxl = withDefaultProps(BpkText, {
  tagName: 'h1',
  textStyle: TEXT_STYLES.hero4,
});
Header1Xxxxxl.displayName = 'Header1Xxxxxl';

export const Header2Lg = withDefaultProps(BpkText, {
  tagName: 'h2',
  textStyle: TEXT_STYLES.bodyLongform,
});
Header2Lg.displayName = 'Header2Lg';

export const Header2Xl = withDefaultProps(BpkText, {
  tagName: 'h2',
  textStyle: TEXT_STYLES.subheading,
});
Header2Xl.displayName = 'Header2Xl';

export const Header2Xxl = withDefaultProps(BpkText, {
  tagName: 'h2',
  textStyle: TEXT_STYLES.heading2,
});
Header2Xxl.displayName = 'Header2Xxl';

export const Header2Xs = withDefaultProps(BpkText, {
  tagName: 'h2',
  textStyle: TEXT_STYLES.caption,
});
Header2Xs.displayName = 'Header2Xs';

export const Header2Sm = withDefaultProps(BpkText, {
  tagName: 'h2',
  textStyle: TEXT_STYLES.footnote,
});
Header2Sm.displayName = 'Header2Sm';

export const Header3Lg = withDefaultProps(BpkText, {
  tagName: 'h3',
  textStyle: TEXT_STYLES.bodyLongform,
});
Header3Lg.displayName = 'Header3Lg';

export const Header3 = withDefaultProps(BpkText, {
  tagName: 'h3',
});
Header3.displayName = 'Header3';

export const Header3Xs = withDefaultProps(BpkText, {
  tagName: 'h3',
  textStyle: TEXT_STYLES.caption,
});
Header3Xs.displayName = 'Header3Xs';

export const Header3Sm = withDefaultProps(BpkText, {
  tagName: 'h3',
  textStyle: TEXT_STYLES.footnote,
});
Header3Sm.displayName = 'Header3Sm';
