import type { ReactElement } from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

type Props = {
  bold?: boolean;
  textStyle: string;
};

const BOLD_STYLES: Record<string, string> = {
  [TEXT_STYLES.caption]: TEXT_STYLES.label3, // xs
  [TEXT_STYLES.footnote]: TEXT_STYLES.label2, // sm
  [TEXT_STYLES.bodyDefault]: TEXT_STYLES.label1, // base
  [TEXT_STYLES.bodyLongform]: TEXT_STYLES.heading4, // lg
  [TEXT_STYLES.subheading]: TEXT_STYLES.heading3, // xl
  [TEXT_STYLES.heading2]: TEXT_STYLES.heading2, // xxl
  [TEXT_STYLES.heading1]: TEXT_STYLES.heading1, // xxxl
  [TEXT_STYLES.hero5]: TEXT_STYLES.hero5, // xxxxl
  [TEXT_STYLES.hero4]: TEXT_STYLES.hero4, // xxxxxl
};

const BpkTextWrapper = ({
  bold = false,
  textStyle = TEXT_STYLES.bodyDefault,
  ...rest
}: Props): ReactElement => {
  const getTextStyle = () =>
    bold && BOLD_STYLES[textStyle] ? BOLD_STYLES[textStyle] : textStyle;

  return <BpkText textStyle={getTextStyle()} {...rest} />;
};

export default BpkTextWrapper;
